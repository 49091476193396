.cardBody {
    text-align: justify;
}
.card {
    margin: 20px 10px;
}
p a {
    font-size: .55rem;
}
.xsmall {
    font-size: 0.75rem;
    /* Make the text small */
    padding: 2px;
    /* Reduce padding */
    margin: 0;
    /* Remove margin */
}